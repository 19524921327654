import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Auth0ProviderWithHistory from '@auth/Auth0ProviderWithHistory';

import { AppbarContextProvider } from '@contexts/AppbarContext';
import { Auth0TokenProvider } from '@contexts/Auth0TokenContext';

import AppWrapper from './AppWrapper';
import { AuthenticationGuard } from './AuthenticationGuard';

const RedirectToOrg = () => {
  const location = useLocation();

  // Use useMemo to stabilize the params object
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const org = params.get('organization_name');

  useEffect(() => {
    if (org) {
      // Remove 'org' from the query parameters
      const remainingQueryString = params.toString();

      // Construct the full redirect URL
      const redirectUrl = `https://app.${org}.cloud.physicsx.ai${remainingQueryString ? `?${remainingQueryString}` : ''}`;
      window.location.href = redirectUrl;
    }
  }, [org, params]);

  // Fallback to prevent rendering
  return null;
};

export const Root = () => {
  const location = useLocation();

  // Handle the redirect before wrapping in guards and providers
  if (location.pathname === '/redirect') {
    return <RedirectToOrg />;
  }

  return (
    <AppbarContextProvider>
      <Auth0ProviderWithHistory>
        <AuthenticationGuard>
          <Auth0TokenProvider>
            <AppWrapper />
          </Auth0TokenProvider>
        </AuthenticationGuard>
      </Auth0ProviderWithHistory>
    </AppbarContextProvider>
  );
};

export default Root;
